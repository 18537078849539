<template>
    <div v-if="issue">
        <Articles-List :isChild="true" :title="title">
            <a v-if="issue.toc" :href="issue.toc" type="button" class="btn btn-secondary" target="_blank">
                <font-awesome-icon icon="file-download" />
                {{translation.toc}}
            </a>
            <div class="placeholder" v-else></div>
        </Articles-List>
    </div>
</template>

<script>
import ArticlesList from './ArticlesList'

export default {
    name: 'IssueArticles',
    components: { ArticlesList },
    mounted() {
        this.$store.dispatch('fetchJournalIssues', this.$route.params)
    },
    computed: {
        title() {
            const items = this.$route.params.issueId.split('-')
            return `Content of Issue ${items[2]}, Volume ${items[1]}, ${items[0]}`            
        },
        issue() {
            return this.$attrs.journal.issues.find(issue => issue.id == this.$route.params.issueId)
        }
    }
}
</script>

<style lang="less" scoped>
.placeholder {
    width: 80px;
}
</style>