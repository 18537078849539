<template>
    <div>
        <Small-Header :doc="doc" />
        <div class="container">
            <div class="row">
                <div class="col">
                    <component :is="hrefs('/profile/index').content"/>
                </div>
            </div>
            <div class="row">
                <div class="col-5">
                    <div class="form-group">
                        <label>{{doc.meta.profile.name.label}}</label>
                        <input type="text" class="form-control" v-model="result.name" :placeholder="doc.meta.profile.name.placeholder">
                    </div>
                    <div class="form-group">
                        <label>{{doc.meta.profile.phone.label}}</label>
                        <input type="tel" class="form-control" v-model="result.phone" :placeholder="doc.meta.profile.phone.placeholder">
                    </div>
                    <div class="form-group">
                        <label>{{doc.meta.profile.orcid.label}}</label>
                        <input type="text" class="form-control" v-model="result.orcid" :placeholder="doc.meta.profile.orcid.placeholder">
                        <a href="https://orcid.org/register" target="_blank">ORCID registration</a>
                    </div>
                    <div class="form-group">
                        <label>{{doc.meta.profile.institution.label}}</label>
                        <input type="text" class="form-control" v-model="result.institution" :placeholder="doc.meta.profile.institution.placeholder">
                    </div>
                    <div class="form-group">
                        <button class="btn btn-primary mb-2 mr-2" @click="saveProfile" :disabled="submitting">{{doc.meta.save}}</button>
                        <button class="btn btn-secondary mb-2 mr-2" @click="$store.dispatch('logout')">{{doc.meta.logout}}</button>
                    </div>
                </div>
            </div>
            <div class="row mt-5 mb-5">
                <div class="col-9">
                    <h3>{{doc.meta.subscriptions.title}}</h3>
                    <table class="table table-light">
                        <thead>
                            <tr>
                                <th v-for="(label, idx) in doc.meta.subscriptions.columns" scope="col" :key="`subscribtion-column-${idx}`">{{label}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="journal in journals" :key="journal.meta.issn">
                                <th scope="row">
                                    <router-link :to="`/journal/${journal.meta.issn}`">
                                        {{journal.meta.title}}
                                    </router-link>
                                </th>
                                <td>{{journal.meta.issn}}</td>
                                <td v-if="!journal.meta.restricted">
                                    {{doc.meta.subscriptions.public}}
                                </td>
                                <td v-else-if="user.subscriptions[journal.meta.issn] && user.subscriptions[journal.meta.issn].expiration && user.subscriptions[journal.meta.issn].expiration.toDate() >= new Date()">
                                    {{doc.meta.subscriptions.subscribedUntil}} {{user.subscriptions[journal.meta.issn].expiration.toDate() | moment( doc.meta.subscriptions.dateFormat )}}
                                </td>
                                <td v-else>
                                    <button class="btn btn-primary" @click="subscribe(journal.meta.issn)">Subscribe</button>
                                    <template v-if="user.subscriptions[journal.meta.issn] && user.subscriptions[journal.meta.issn].request">
                                        <br>
                                        {{doc.meta.subscriptions.subscribeRequestSent}} {{user.subscriptions[journal.meta.issn].request.toDate() | moment( doc.meta.subscriptions.dateFormat ) }}
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import VueMoment from 'vue-moment'

Vue.use(VueMoment)

export default {
    data: () => ({
        subscribed: new Date('2020-11-01'),
        subscribeSent: false,
        result: {
            name: '',
            orcid: '',
            phone: '',
            institution: ''
        },
        submitting: false
    }),
    mounted () {
        this.refreshProfileFromStore()
    },
    methods: {
        async saveProfile() {
            this.submitting = true
            await this.$store.dispatch('updateUserProfile', this.result)
            this.submitting = false
            this.refreshProfileFromStore()
        },
        refreshProfileFromStore() {
            for (let path in this.result) {
                this.result[path] = this.user.profile[path]
            }
        },
        async subscribe(issn) {
            this.submitting = true
            await this.$store.dispatch('subscribeToJournal', issn)
            this.submitting = false

        }
    },
    computed: {
        doc() {
            return this.hrefs('/profile/index')
        },
        journals() {
            return this.journalsList.map(j => this.hrefs(`/journals/${j.issn}`)).filter(j=>j).sort(j => j.meta.order)
        },
        ...mapGetters(['user', 'journalsList', 'profile'])
    },
    watch: {
        profile() {
            this.refreshProfileFromStore()
        }
    }
}
</script>