import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import search from './store/search'
import journals from './store/journals'
import user from './store/user'

import { translation } from '@content/documents/translation.yml'

const fb = require('./firebaseConfig.js')

const store = new Vuex.Store({
    state: {
        documents: Object.keys(translation).reduce((acc,val) => {acc[val] = {}; return acc}, {}),
        settings: {
            lang: Object.keys(translation)[0]
        }
    },
    modules: {
        search, journals, user
    },
    actions: {
        setLanguage({commit}, lang) {
            if(translation[lang]) {
                commit('setLanguage', lang)
                fb.auth.languageCode = lang
            }
        }
    },
    mutations: {
        setLanguage(state, lang) {
            state.settings.lang = lang
        },
        saveDocument(state, {lang, href, data}) {
            state.documents[lang] = state.documents[lang] || {}
            state.documents[lang][href] = data
        }
    },
    getters: {
        settings (state) {
            return state.settings
        }
    }
})

export default store
