<template>
    <div class="news-view">
        <!-- <Small-Header /> -->
        <div class="container mt-5">
            <div class="row">
                <h2>{{translation.news}}</h2>
                <hr>
                <div class="row row-cols-1">
                    <div class="col" v-for="item in newsItems" :key="`news-item-${item.href}`">
                        <div class="card mb-3 mr-3">
                            <div class="row g-0" :to="{name: 'page', params: {href: item.meta.href}}">
                                <div class="col-md-8">
                                    <div class="card-body">
                                        <h5 class="card-title">{{item.meta.title}}</h5>
                                        <p class="card-text"><small class="text-muted">{{item.meta.subtitle}}</small></p>
                                        <p class="card-text desc">{{item.meta.description}}</p>
                                        <router-link :to="{name: 'page', params: {href: item.meta.href}}" class="btn btn-primary">{{translation.findOutMore}}</router-link>
                                    </div>
                                </div>
                                <div class="col-md-4" v-if="item.meta.image">
                                    <img :src="item.meta.image" class="p-3" :alt="item.meta.title" >
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NewsList",
    computed: {
        newsItems() {
            let items = this.hrefs('/news/*') || []
            return [].concat(items).filter(item => item.meta.active !== false).sort((a,b) => b.meta.order - a.meta.order).slice(0,3)
        },
        doc() {
            //hack - create document meta
            return {meta: {title: this.translation.title + ' - ' + this.translation.news }}
        }
    }
}
</script>

<style scoped>
img {
    object-fit: contain;
    height: 100%;
    width: 100%;
}
.desc {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3; 
    -webkit-box-orient: vertical;
}
</style>