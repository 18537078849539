<template>
    <div class="journals-page">
        <Header :doc="doc"/>
        <div class="container">
            <div class="row">
                <div class="col-md-8 mb-5 content">
                    <component :is="hrefs('/journals').content" />
                </div>
                <div class="col-md-4 mb-5 contacts">
                    <component :is="hrefs('/contacts').content" />
                </div>
                
            </div>
            <div class="row" v-if="journals.length > 1">
                <div class="col-md-4 mb-5" v-for="journal in journals" :key="journal.meta.issn">
                    
                    <div class="card h-100">
                        <router-link :to="`/journal/${journal.meta.issn}`">
                            <img class="card-img-top mt-3" :src="journal.meta.image" :alt="journal.meta.title">
                        </router-link>
                        <p class="text-center"><small>ISSN: {{journal.meta.issn}}</small></p>
                        <div class="card-body pt-0 mt-0">
                            <h4 class="card-title">{{journal.meta.title}}</h4>
                            <p class="card-text">{{journal.meta.description}}</p>
                        </div>
                        <div class="card-footer">
                            <router-link :to="`/journal/${journal.meta.issn}`" class="btn btn-primary">
                                <font-awesome-icon icon="search" />
                                {{translation.exploreJournal}}
                            </router-link>
                        </div>
                    </div>
                    
                </div>
            </div>
            <div class="row router-view mb-5" v-else-if="journalsList.length && journalsList[0].issues">
                <h5>{{ translation.recentIssues }}</h5>
                <div class="issues-view-wrapper">
                    <router-view :journal="journalsList[0]" :doc="journals[0]" :limit="3" />
                </div>
                <div class="col-md-12 mt-5 mb-5">
                    <div class="card">
                        <router-link :to="`/journal/${journals[0].meta.issn}`" class="btn btn-primary">
                            <font-awesome-icon icon="search" />
                            {{translation.exploreJournal}}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NewsList from '../components/NewsList'
import { mapGetters } from 'vuex'

export default {
    name: 'Journals',
    components: {
        NewsList
    },
    data: () => ({
        
    }),
    computed: {
        journals() {
            return [].concat(this.hrefs(`/journals/*`)).sort( (a,b) => a.meta.order - b.meta.order)
        },
        doc() {
            return this.hrefs('/journals')
        },
        ... mapGetters(['journalsList'])
    }
}
</script>

<style lang="scss" scoped>
img.card-img-top {
    width: 50%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-height: 300px;
}
.card-issn {
    text-align: center;
}
.contacts {
    /deep/ img {
        max-width: 300px;
    }
}

.router-view {
    .issues-view-wrapper > div {
        display: flex;
        // /deep/ .row {
        //     display: inline;
        // }
        /deep/ .card-header {
            display: none;
        }
        /deep/ .card {
            border: none;
        }
        .btn {
            margin: auto 30%;
        }
    }
}
</style>