<template>
    <div>
        <transition name="fade">
            <div v-if="performingRequest" class="loading">
                <p>{{ translation.loading }}</p>
            </div>
        </transition>
        <Small-Header :doc="doc" />
        <div class="container">
            <transition name="fade">
            <div class="row mb-5" v-if="showLoginForm">
                <div class="col-sm">
                    <component :is="hrefs('/login/login').content" />
                    <div class="form-row mb-3">
                        <div class="col-3">
                            <label class="sr-only">{{loginMeta.email}}</label>
                            <input name="email" class="form-control" v-model.trim="loginForm.email" type="email" :placeholder="loginMeta.email"/>
                        </div>
                        <div class="col-3">
                            <label class="sr-only">{{loginMeta.password}}</label>
                            <input name="password" class="form-control" v-model.trim="loginForm.password" type="password" :placeholder="loginMeta.password"/>
                        </div>
                        <div class="col-2">
                            <button @click="login" class="btn btn-primary">{{loginMeta.submit}}</button>
                        </div>
                    </div>
                    <transition name="fade">
                        <p class="error" v-if="errorMsg">{{ errorMsg }}</p>
                    </transition>

                    <a class="link" @click="toggleForm">{{loginMeta.signup}}</a>
                    <a class="link" @click="togglePasswordReset">{{loginMeta.lost}}</a>
                </div>
            </div>
            </transition>
            <transition name="fade">
            <div class="row mb-5" v-if="!showLoginForm && !showForgotPassword">
                <div class="col-sm">
                    <component :is="hrefs('/login/signup').content" />
                    <div class="form-row mb-3">
                        <div class="col-3">
                            <label class="sr-only">{{signupMeta.email}}</label>
                            <input name="email" class="form-control" v-model.trim="signupForm.email" type="email" :placeholder="signupMeta.email"/>
                        </div>
                        <div class="col-3">
                            <label class="sr-only">{{signupMeta.password}}</label>
                            <input name="password" class="form-control" v-model.trim="signupForm.password" type="password" :placeholder="signupMeta.password"/>
                        </div>
                        <div class="col-2">
                            <button @click="signup" class="btn btn-primary">{{signupMeta.submit}}</button>
                        </div>
                    </div>
                    <transition name="fade">
                        <p class="error" v-if="errorMsg">{{ errorMsg }}</p>
                    </transition>

                    <a class="link" @click="toggleForm">{{signupMeta.back}}</a>
                </div>
            </div>
            </transition>
            <transition name="fade">
            <div class="row mb-5" v-if="!showLoginForm && showForgotPassword">
                <div class="col-sm">
                    <component :is="hrefs('/login/reset').content" />
                    <div class="form-row mb-3" v-if="!passwordResetSuccess">
                        <div class="col-3">
                            <label class="sr-only">{{resetMeta.email}}</label>
                            <input name="email" class="form-control" v-model.trim="passwordForm.email" type="email" :placeholder="resetMeta.email"/>
                        </div>
                        <div class="col-2">
                            <button @click="resetPassword" class="btn btn-primary">{{resetMeta.submit}}</button>
                        </div>
                    </div>
                    <div v-else>
                        <component :is="hrefs('/login/reset-sent').content" />
                    </div>
                    <transition name="fade">
                        <p class="error" v-if="errorMsg">{{ errorMsg }}</p>
                    </transition>
                    <a class="link" @click="toggleForm">{{signupMeta.back}}</a>
                </div>
            </div>
            </transition>
        </div>
    </div>
</template>
<style lang="less" scoped>
.link {
    cursor: pointer;
    text-decoration: underline;
    padding-right: 5px;
}
.error {
    color: red;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>

<script>
const fb = require('../firebaseConfig.js')

export default {
    //mixins: [hreflang],
    data() {
        return {
            loginForm: {
                email: '',
                password: ''
            },
            signupForm: {
                name: '',
                title: '',
                email: '',
                password: ''
            },
            passwordForm: {
                email: ''
            },
            showLoginForm: true,
            showForgotPassword: false,
            passwordResetSuccess: false,
            performingRequest: false,
            errorMsg: ''
        }
    },
    mounted() {
        fb.auth.onAuthStateChanged(async user => {
            console.log('Auth', user)
            if (user) {
                this.$router.push('/home')
            }
        })
    },
    methods: {
        toggleForm() {
            this.errorMsg = ''
            this.showForgotPassword = false
            this.showLoginForm = !this.showLoginForm
        },
        togglePasswordReset() {
            if (this.showForgotPassword) {
                this.showLoginForm = true
                this.showForgotPassword = false
                this.passwordResetSuccess = false
            } else {
                this.showLoginForm = false
                this.showForgotPassword = true
            }
        },
        login() {
            this.performingRequest = true

            fb.auth.signInWithEmailAndPassword(this.loginForm.email, this.loginForm.password).then(() => {
                this.performingRequest = false
            }).catch(err => {
                console.log(err)
                this.performingRequest = false
                this.errorMsg = this.docMeta.errors[err.code] || err.message
            })
        },
        signup() {
            this.performingRequest = true

            fb.auth.createUserWithEmailAndPassword(this.signupForm.email, this.signupForm.password).then(res => {
                //this.$store.commit('setCurrentUser', res.user)

                // create user obj
                fb.profilesCollection.doc(res.user.uid).set({email: this.signupForm.email}).then(() => {
                    this.performingRequest = false
                }).catch(err => {
                    console.log(err)
                    this.performingRequest = false
                    this.errorMsg = this.docMeta.errors[err.code] || err.message
                })
            }).catch(err => {
                console.log(err)
                this.performingRequest = false
                this.errorMsg = this.docMeta.errors[err.code] || err.message
            })
        },
        resetPassword() {
            this.performingRequest = true

            fb.auth.sendPasswordResetEmail(this.passwordForm.email).then(() => {
                this.performingRequest = false
                this.passwordResetSuccess = true
                this.passwordForm.email = ''
            }).catch(err => {
                console.log(err)
                this.performingRequest = false
                this.errorMsg = this.docMeta.errors[err.code] || err.message
            })
        }
    },
    computed: {
        doc () {
            return this.docMeta
        },
        docMeta() {
            return this.hrefs('/login/index').meta
        },
        loginMeta() {
            return this.hrefs('/login/login').meta
        },
        signupMeta() {
            return this.hrefs('/login/signup').meta
        },
        resetMeta() {
            return this.hrefs('/login/reset').meta
        }
    }
}
</script>