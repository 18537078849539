<template>
    <div v-if="doc" class="page">
        <Header v-if="doc.meta.header == 'large'" />
        <Small-Header v-if="doc.meta.header == 'small'" />
        <div class="container">
            <component :is="doc.content" />
        </div>
    </div>
</template>

<script>

export default {
    name: 'Page',
    mounted() {
        // if(!this.doc) {
        //     this.$router.back()
        // }
    },
    computed: {
        doc() {
            let href
            if(this.$attrs.href) href = this.$attrs.href
            else if (this.$route.params.href) href = '/' + this.$route.params.href
            else if (this.$route.query.href) href = this.$route.query.href
            return this.hrefs( decodeURIComponent(href).replace('//', '/') )
        }
    }
}
</script>

<style lang="scss" scoped>
.page {
    .container {
        /deep/ .frontmatter-markdown {
            min-height: 50vh;
            img {
                position: relative;
                float: right;
                margin-left: 1rem;
                max-height: 250px !important;
                width: 40%;
                object-fit: contain;
            }
            blockquote {
                img {
                    float: unset;
                    width: 80%;
                }
            }
        }
    }
}
</style>