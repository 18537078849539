import Vue from 'vue'

const fb = require('../firebaseConfig.js')

function loadTemplate(template, data = {}) {
    const Template = Vue.extend(template.vue.component)
    let cmp = new Template({
        props: ['meta', 'data'],
        propsData: {data, meta: template.attributes}
    })
    const templateHtml = cmp.$mount(document.createElement('div')).$el.innerHTML
    cmp.$destroy()
    return Object.assign({html: templateHtml},  template.attributes)
}

const getDefaultState = () => {
    return {
        user: {
            userId: null,
            profile: {},
            subscriptions: {}
        }
    }
}

export default {

    state: getDefaultState(),

    actions: {
        async selectUser({dispatch, commit}, userId) {
            commit('flushState')
            if(!userId) return

            commit('setUserData', {userId, email: fb.auth.currentUser.email})
            await dispatch('fetchUserProfile')
        },
        async fetchUserProfile({ commit, state }) {
            let res = await fb.userCollection.doc(state.user.userId).get()
            if(!res.exists) return console.log('no user profile created', res)
            commit('setUserData', {profile: await res.data()})

            const subscriptionDocs = await fb.userCollection.doc(state.user.userId).collection('subscriptions').get()
            let subscriptions = {}
            for(let subscriptionDoc of subscriptionDocs.docs) {
                subscriptions[subscriptionDoc.id] = await subscriptionDoc.data()                
            }
            commit('setUserData', {subscriptions})
        },
        async updateUserProfile({state, dispatch}, data) {
            let hasProfile = await fb.userCollection.doc(state.user.userId).get()
            if(!hasProfile.exists) await fb.userCollection.doc(state.user.userId).set({})

            let record = {}
            for(let key in data) {
                if(data[key] !== undefined) record[key] = data[key]
            }

            await fb.userCollection.doc(state.user.userId).update(record)
            await dispatch('fetchUserProfile')
        },
        async subscribeToJournal({state, rootState, dispatch}, issn) {
            const journal = rootState.journals.list.find(j => j.issn == issn)
            let message = ''
            const profile = {
                email: state.user.email,
                ...state.user.profile
            }

            const subscriptionRequestConfirmationMail = this.hrefs('/templates/subscription-confirmation')
            message = loadTemplate(subscriptionRequestConfirmationMail, {journal, profile})
            await fb.mailCollection.add({
                from: `SciBulCom <noreply@mg.b-smart.tech>`,
                to: profile.email,
                message
            })
            
            const subscriptionRequestNotificationMail = this.hrefs('/templates/subscription-request')
            message = loadTemplate(subscriptionRequestNotificationMail, {journal, profile})
            message.subject += ' - ' + state.user.profile.name || state.user.profile.email
            await fb.mailCollection.add({
                from: `SciBulCom <noreply@mg.b-smart.tech>`,
                to: subscriptionRequestNotificationMail.attributes.to,
                replyTo: profile.email,
                message
            })

            await fb.userCollection.doc(state.user.userId).collection('subscriptions').doc(issn).set({request: new Date()}, {merge: true})
            await dispatch('fetchUserProfile')
        },
        async logout({commit}) {
            await fb.auth.signOut()
            commit('flushState')
        }
    },

    mutations: {
        setUserData(state, payload) {
            for (let key in payload) {
                Vue.set(state.user, key, payload[key])
            }
        },
        flushState(state) {
            Object.assign(state, getDefaultState())
        }
    },

    getters: {
        logged(state) {
            return state.user.userId
        },
        user(state) {
            return state.user
        },
        profile(state) {
            return state.user.profile
        }
    },

}