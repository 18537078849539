<template>
    <div class="article-view">
        <Small-Header :doc="doc" />
        <div class="container mt-5" v-if="article">
            <div class="row">
                <div class="col">
                    <h2>{{article.title}}</h2>
                    <p class="mb-2">
                        <small class="text-muted">
                            {{translation.journal}}:
                            <router-link
                                :to="`/journal/${article.issn}`" 
                                >{{article.journal}}
                            </router-link>
                            <router-link
                                :to="`/journal/${article.issn}/issue/${article.year}-${article.volume}-${article.issue}`" 
                                >{{article.volume}}({{article.issue}}) ({{article.year}})
                            </router-link>
                        </small>
                        <small class="text-muted ml-3">
                            {{translation.pages}}: {{article.pages.join(' - ')}}
                        </small>
                    </p>
                    
                    <details open class="mb-2 ">
                        <summary class="text-info">{{translation.authors}}</summary>
                        <router-link v-for="(author,auidx) in article.authors" :key="auidx" :to="`/journal/${article.issn}/authors/${author.name}`">
                            {{author.fullName}}<span v-if="article.authors.length > 1 && auidx < article.authors.length - 1">;</span>
                        </router-link>
                    </details>
                    
                    <details open class="mb-2">
                        <summary class="text-info">{{translation.abstract}}</summary>
                        <p v-html="article.abstract"></p>
                    </details>
                    
                    <details open class="mb-2">
                        <summary class="text-info">{{translation.keywords}}</summary>
                        <p v-if="article.keywords" v-html="article.keywords"></p>
                        <p v-else class="muted-text">{{translation.noKeywords}}</p>
                    </details>

                    <details open class="mb-2 ">
                        <summary class="text-info">{{translation.cite}}</summary>
                        <p v-if="article.citations && article.citations.html" v-html="article.citations.html"></p>
                        <a v-else>
                            {{ article.authors.slice(0, 3).map(a => a.fullName).join('; ') }}
                            <span v-if="article.authors.length > 3"> et al.</span>
                            <span>({{ article.year }}), </span>
                            <span>{{article.title}}, </span>
                            <em>{{article.journal}}, </em>
                            <span>{{article.volume}}({{article.issue}}), {{article.pages.join('-')}}.</span>
                        </a>
                        <button v-if="article.citations && article.citations.ris" class="btn btn-primary" @click="downloadEndNote">
                            <font-awesome-icon icon="download" />
                            EndNote
                        </button>
                    </details>
                </div>
            </div>
            <template v-if="article.file">
                <template v-if="hasAccess !== false">
                    <div class="row pdf-navigation pb-2">
                        <div class="col">
                            <h5 class="text-info">{{translation.fullText}}</h5>
                            <button class="btn btn-primary" @click="page-=2" :disabled="page<=1">
                                <font-awesome-icon icon="angle-double-left" />
                            </button> 
                            {{page}}/{{pageCount}}
                            <button class="btn btn-primary" @click="page+=2" :disabled="page>=pageCount">
                                <font-awesome-icon icon="angle-double-right" />
                            </button>
                            <a class="btn ml-3 btn-primary" :href="manuscript" target="_blank" role="button">
                                <font-awesome-icon icon="download" />
                                {{translation.download}}
                            </a>
                        </div>
                    </div>
                    <div class="row pdf-viewer border border-secondary">
                        <div class="col-lg-6">
                            <div v-if="loadedRatio > 0 && loadedRatio < 1" style="background-color: green; color: white; text-align: center" :style="{ width: loadedRatio * 100 + '%' }">{{ Math.floor(loadedRatio * 100) }}%</div>
                            <pdf 
                                class="pdf" 
                                :src="manuscript"
                                @num-pages="pageCount = $event"
                                @page-loaded="currentPage = $event"
                                @progress="loadedRatio = $event"
                                :page="page"
                            />
                        </div>
                        <div class="col-lg-6">
                            <div v-if="loadedRatio > 0 && loadedRatio < 1" style="background-color: green; color: white; text-align: center" :style="{ width: loadedRatio * 100 + '%' }">{{ Math.floor(loadedRatio * 100) }}%</div>
                            <pdf 
                                class="pdf" 
                                :src="manuscript"
                                :page="page+1"
                            />
                        </div>
                    </div>
                </template>
                <div class="row" v-else>
                    <div class="col-lg-6">
                    <p>{{translation.paidAccessMessage}}</p>
                    <router-link to="/profile">{{translation.requestPaidAccess}}</router-link>
                    </div>
                </div>
            </template>
            <div v-else class="row">
                <div class="col">
                    <h5>{{translation.noFullTextAvailable}}</h5>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import pdf from 'vue-pdf'
const fb = require('../firebaseConfig.js')

export default {
    components: { pdf },
    data: () => ({
        page: 1,
        pageCount: 0,
        currentPage: 0,
        loadedRatio: 0,
        manuscript: null,
        hasAccess: null,
    }),
    mounted() {
        this.$store.dispatch('loadArticle', this.$route.params.articleId)
    },
    computed: {
        article() {
            return this.$store.state.search.article
        },
        doc() {
            return this.hrefs('/article')
        },
        restricted() { 
            return this.hrefs('/journal/index').meta.items.find(item => item.issn == this.article.issn).restricted
        }
    },
    watch: {
        article(article) {
            if(article && article.file) {
                fb.storageRef.child(article.file).getDownloadURL().then(url => this.manuscript = url).catch(() => {
                    this.hasAccess = false
                })
            }
        },
        page() {
            if(this.page < 1) this.page = 1
            if(this.page > this.pageCount) this.page = this.pageCount
        }
    },
    methods: {
        downloadEndNote() {
            const filename = this.article.title + '.ris'
            const text = this.article.citations.ris

            let blob = new Blob([text], {type: 'text/ris'})
            if(window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(blob, filename);
            }
            else {
                let elem = window.document.createElement('a');
                elem.href = window.URL.createObjectURL(blob);
                elem.download = filename;        
                document.body.appendChild(elem);
                elem.click();        
                document.body.removeChild(elem);
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.pdf-viewer {
    overflow: hidden;
    // overflow-x: auto;
    // height: 80vh;
}
.pdf-navigation {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 100px;
    z-index: 10000;
    background-color: white;
}

.row:last-of-type {
    margin-bottom: 50px;
}
</style>